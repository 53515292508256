<template>
  <v-container>
    <v-overlay absolute>
      <div
        class="accent pa-6 headline rounded"
        v-text="$t('multiproject.automatic.message')"
      />
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'AutomaticContainer',
}
</script>
